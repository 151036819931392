
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/UI/Icone.vue';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IDTOCategoria } from '@/models/DTO/IDTOCategoria';
import ServicoCategoria from '@/servicos/ServicoCategoria';
import { ICategoriaParametrosConsulta } from '@/models/ParametrosRequisicao/Consulta/ICategoriaParametrosConsulta';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { EStatusResposta, IResposta } from '@/core/models/IResposta';
import { IOptionString } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'CategoriaListagem',
  components: {
    Icone,
    ComunicacaoApi,
  },
  setup() {
    const { appBase, apresentarComunicacaoApi, apresentarResposta } = useAppBase();
    const servicoCategoria = new ServicoCategoria();

    const state = reactive({
      categorias: [] as IDTOCategoria[],
      parametrosConsulta: {} as ICategoriaParametrosConsulta,
      apresentarFiltros: false,
      ordenarPor: 'CodigoCategoriaDESC',
      opcoesOrdenacao: [] as IOptionString[],
    });
    let debounce = 0;

    state.opcoesOrdenacao = [];
    const ordemMaisRecentes:IOptionString = { value: 'CodigoCategoriaDESC', label: 'Mais recentes' } as IOptionString;
    const ordemNomeAsc:IOptionString = { value: 'NomeCategoriaASC', label: 'Nome (A-Z)' } as IOptionString;
    const ordemNomeDesc:IOptionString = { value: 'NomeCategoriaDESC', label: 'Nome (Z-A)' } as IOptionString;
    state.opcoesOrdenacao.push(ordemMaisRecentes);
    state.opcoesOrdenacao.push(ordemNomeAsc);
    state.opcoesOrdenacao.push(ordemNomeDesc);

    async function buscarDados() {
      appBase.carregando = true;
      state.apresentarFiltros = false;
      state.categorias = [];
      if (UtilitarioGeral.valorValido(state.ordenarPor)) {
        state.parametrosConsulta.ordenacao = [];
        state.parametrosConsulta.ordenacao.push(state.ordenarPor);
      }

      if (appBase.paginacao.paginaAtual > 0) { state.parametrosConsulta.numeroPagina = appBase.paginacao.paginaAtual; }
      if (appBase.paginacao.qtdeRegistrosPagina > 0) { state.parametrosConsulta.qtdeRegistrosPagina = appBase.paginacao.qtdeRegistrosPagina; }
      state.parametrosConsulta.qtdeRegistrosTotal = 100;
      const respostaConsulta = await servicoCategoria.obterCategorias(state.parametrosConsulta);
      if (UtilitarioGeral.validaLista(respostaConsulta.dados)) {
        state.categorias = respostaConsulta.dados;
        appBase.paginacao = respostaConsulta.paginacao;
      }

      appBase.carregando = false;
    }

    function buscarCategoriaPorNome() {
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        await buscarDados();
      }, 600);
    }
    async function limparFiltros() {
      state.parametrosConsulta.status = undefined;
      await buscarDados();
    }
    onBeforeMount(async () => {
      await buscarDados();
    });

    async function paginacaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = current;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }
    async function qtdeRegistrosPaginaAlterada(current: number, pageSize: number) {
      appBase.paginacao.paginaAtual = 1;
      appBase.paginacao.qtdeRegistrosPagina = pageSize;
      await buscarDados();
    }

    async function deletarCategoria(codigo: number) {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor...');
      appBase.resposta = await servicoCategoria.deletar(codigo);
      if (appBase.resposta.status === EStatusResposta.Sucesso) {
        const index = state.categorias.findIndex((u) => u.codigo === codigo);
        if (index >= 0) {
          state.categorias.splice(index, 1);
        }
      }
      apresentarResposta();
    }
    function confirmaExclusao(Categoria:IDTOCategoria) {
      Modal.confirm({
        title: 'Você confirma a exclusão da categoria:',
        content: `${Categoria.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await deletarCategoria(Categoria.codigo); },
      });
    }
    return {
      appBase,
      state,
      UtilitarioMascara,
      buscarDados,
      limparFiltros,
      buscarCategoriaPorNome,
      paginacaAlterada,
      qtdeRegistrosPaginaAlterada,
      confirmaExclusao,
    };
  },
});
